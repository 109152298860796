import * as React from 'react'
import { Trans } from '@lingui/macro'

import { Link } from '../../lib/components/link'
import css from './styles.css'
import { ItemType } from '../../api/types'

type Props = {
    discogsId: number
    itemType: ItemType
}

export function ReportAbuse(props: Props): React.ReactElement {
    const { discogsId, itemType } = props

    const href = { MASTER_RELEASE: ``, RELEASE: `/release/${discogsId}/report` }

    return (
        <div className={css.report}>
            <Link href={href[itemType as keyof typeof href]}>
                <Trans>Report Suspicious Activity</Trans>
            </Link>
        </div>
    )
}
