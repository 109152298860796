import * as React from 'react'
import { Trans } from '@lingui/macro'

import { groupByRole } from '../../lib/group-by-role'
import { Section } from '../../lib/components/section'
import { ArtistLink } from '../artist-link'

import css from './styles.css'

import { ReleaseCreditsFragment, ReleaseCredit } from '../../api/types'
import { Typography } from '@mui/material'

export function sortByName(a: ReleaseCredit, b: ReleaseCredit): number {
    const aname = a.artist?.name ?? ''
    const bname = b.artist?.name ?? ''

    if (aname === bname) {
        return 0
    }

    return aname < bname ? -1 : 1
}

export function ReleaseCredits(props: ReleaseCreditsFragment): React.ReactElement | null {
    const { releaseCredits = [] } = props

    if (releaseCredits.length === 0) {
        return null
    }

    const roles = groupByRole(releaseCredits)
    return (
        <Section
            id='release-credits'
            title={
                <Typography variant='headLineLarge'>
                    <Trans>Credits</Trans>
                </Typography>
            }
        >
            <ul className={css.list}>
                {Object.entries(roles).map(([role, credits]: [string, ReleaseCredit[]]) => (
                    <li key={role}>
                        <Typography variant='labelSmall'>
                            <span className={css.role}>{role}</span>
                            {' – '}
                            {credits.sort(sortByName).map(
                                (credit: ReleaseCredit, i: number): React.ReactElement | undefined =>
                                    credit.artist && (
                                        <React.Fragment key={credit.artist.name}>
                                            <ArtistLink
                                                artist={credit.artist}
                                                nameVariation={credit.nameVariation}
                                                displayName={credit.displayName}
                                            />
                                            {credit.applicableTracks ? (
                                                <>
                                                    {' '}
                                                    (<Trans>tracks: {credit.applicableTracks}</Trans>)
                                                </>
                                            ) : null}
                                            {i < credits.length - 1 && ', '}
                                        </React.Fragment>
                                    ),
                            )}
                        </Typography>
                    </li>
                ))}
            </ul>
        </Section>
    )
}
