import * as React from 'react'
import classnames from 'classnames'
import { I18n } from '@lingui/core'
import { Trans, t, plural } from '@lingui/macro'
import { CaretDown } from '@discogs/amped/icons'

import { NoteFieldType } from '../../api/types'
import { Edit } from '../../lib/components/icon'
import { Button } from '../../lib/components/buttons'
import { Spinner } from '../../lib/components/spinner'
import { TextArea } from '../../lib/components/textarea'
import { useLocalisation } from '../../lib/i18n'
import { useId } from '../../lib/use-id'

import css from './box.css'

type BoxProps = {
    title: string | React.ReactNode
    onRemove: () => void
    children: React.ReactNode
    className: string
    added: Date
    removing: boolean
}

export function Box(props: BoxProps): React.ReactElement {
    const { className, title, added, onRemove, children, removing } = props
    const { i18n } = useLocalisation()

    return (
        <div className={classnames(css.box, className)}>
            <div className={css.header}>
                <h3>{title}</h3>
                <span className={css.added} title={added.toLocaleString()}>
                    {when(i18n, added)}
                </span>
                <span className={css.spacer} />
                <Button className={css.remove} color='link' small onClick={onRemove} disabled={removing}>
                    {removing && <Spinner />} <Trans>Remove</Trans>
                </Button>
            </div>
            {children}
        </div>
    )
}

function when(i18n: I18n, date: Date): string {
    const diff = Math.floor((Date.now() - date.getTime()) / 1000)

    const years = Math.floor(diff / 31536000)
    const months = Math.floor(diff / 2592000)
    const days = Math.floor(diff / 86400)
    const hours = Math.floor(diff / 3600)
    const minutes = Math.floor(diff / 60)

    if (years > 1) {
        return t(i18n)`${plural(years, { one: 'Added # year ago', other: 'Added # years ago' })}`
    }
    if (months > 1) {
        return t(i18n)`${plural(months, { one: 'Added # month ago', other: 'Added # months ago' })}`
    }
    if (days > 1) {
        return t(i18n)`${plural(days, { one: 'Added # day ago', other: 'Added # days ago' })}`
    }
    if (hours > 1) {
        return t(i18n)`${plural(hours, { one: 'Added # hour ago', other: 'Added # hours ago' })}`
    }
    if (minutes > 1) {
        return t(i18n)`${plural(minutes, { one: 'Added # minute ago', other: 'Added # minutes ago' })}`
    }

    return t(i18n)`Added just now`
}

export type Option = {
    name: string
    value: string
}

type SharedProps = {
    name: string | React.ReactElement
    id: string
}

type FieldProps = (DropdownFieldProps | TextFieldProps) & Omit<SharedProps, 'id'>

function Render(props: FieldProps): React.ReactElement {
    if (props.type === NoteFieldType.Dropdown) {
        return <DropdownField {...props} />
    }

    return <TextField {...props} />
}

export function Field(props: FieldProps): React.ReactElement {
    const { name } = props
    const id = useId()
    return (
        <div className={css.field}>
            <label className={css.label} htmlFor={id}>
                {name}
            </label>
            <Render {...props} id={id} />
        </div>
    )
}

type DropdownFieldProps = {
    type: 'DROPDOWN'
    value: string | Option
    options?: Option[]
    loadOptions?: () => Promise<Option[]>
    onChange: (value: string, name: string) => void
    noEmpty?: boolean
    sort?: (a: Option, b: Option) => number
    id?: string
}

type OptionState = {
    loading: boolean
    options: Option[] | null
}

function DropdownField(props: DropdownFieldProps): React.ReactElement {
    const { value: v, onChange, noEmpty = false, sort = false, loadOptions, id } = props
    const value: Option = typeof v === 'string' ? { value: v, name: v } : v

    const ref = React.useRef<HTMLSelectElement>(null)

    const [options, setOptions] = React.useState({
        loading: false,
        options: props.options ?? null,
    })

    // Filter out duplicate values
    const items = (options.options ?? []).filter(function (opt: Option, index: number, arr: Option[]): boolean {
        const idx = arr.findIndex((other: Option): boolean => opt.value === other.value)
        return idx === index
    })

    if (sort) {
        items.sort(sort)
    }

    function handleChange(evt: React.ChangeEvent<HTMLSelectElement>): void {
        const option = options.options?.find((opt: Option): boolean => opt.value === evt.target.value)
        if (!option) {
            return
        }
        onChange(evt.target.value, option.name)
    }

    async function handleMouseDown(event: React.MouseEvent<HTMLSelectElement>): Promise<void> {
        if (options.options || !loadOptions) {
            return
        }

        if (options.loading) {
            return
        }

        setOptions(
            (opts: OptionState): OptionState => ({
                ...opts,
                loading: true,
            }),
        )

        setOptions({
            loading: false,
            options: await loadOptions(),
        })

        const evt = new MouseEvent('mousedown')
        ref.current?.dispatchEvent(evt)
    }

    return (
        <div className={css.wrapper}>
            {!value.name && !noEmpty && (
                <div className={css.edit}>
                    <Trans>Edit</Trans>
                </div>
            )}
            {value.name && <div className={css.dvalue}>{value.name}</div>}
            <select
                className={css.dropdown}
                value={value.value}
                onChange={handleChange}
                onMouseDown={handleMouseDown}
                ref={ref}
                id={id}
            >
                {!noEmpty && <option value='' />}
                {items.map(
                    (opt: Option): React.ReactElement => (
                        <option value={opt.value} key={opt.value}>
                            {opt.name}
                        </option>
                    ),
                )}
            </select>
            {!options.loading && (
                <div className={css.fieldIcon}>
                    <CaretDown role='img' aria-hidden='true' css={{}} />
                </div>
            )}
            {options.loading && <Spinner className={css.loading} />}
        </div>
    )
}

type TextFieldProps = {
    type: 'TEXTAREA'
    value: string
    render?: React.ReactNode
    id?: string
    onChange: (value: string) => void
}

function TextField(props: TextFieldProps): React.ReactElement {
    const { value, id, render, onChange } = props
    const { i18n } = useLocalisation()

    const ref = React.useRef<HTMLTextAreaElement>(null)
    const [editing, setEditing] = React.useState(false)
    const [inputValue, setInputValue] = React.useState(value)

    React.useEffect((): void => setInputValue(value), [value])
    React.useEffect(
        function (): void {
            ref.current?.focus()
            ref.current?.setSelectionRange(value.length, value.length)
        },
        [editing],
    )

    function handleChange(evt: React.ChangeEvent<HTMLTextAreaElement>): void {
        setInputValue(evt.target.value.substring(0, 254))
    }

    function handleSave(): void {
        onChange(inputValue)
        setEditing(false)
    }

    function handleCancel(): void {
        setInputValue(value)
        setEditing(false)
    }

    function handleEdit(): void {
        setEditing(true)
    }

    if (editing) {
        return (
            <div className={css.textedit}>
                <TextArea
                    ref={ref}
                    rows={4}
                    onChange={handleChange}
                    value={inputValue}
                    className={css.textarea}
                    id={id}
                />
                <Button type='button' onClick={handleSave} small color='green' className={css.save}>
                    <Trans>Save</Trans>
                </Button>
                <Button type='button' onClick={handleCancel} small>
                    <Trans>Cancel</Trans>
                </Button>
            </div>
        )
    }

    return (
        <button className={css.wrapper} onClick={handleEdit} type='button' aria-label={t(i18n)`Edit Notes`}>
            {value ? <div className={css.value}>{render ?? value}</div> : <span className={css.edit}>Edit</span>}
            <Edit className={css.fieldIcon} aria-hidden='true' />
        </button>
    )
}
