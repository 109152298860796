import { ApolloClient } from '@apollo/client'
import { createPage } from '../../lib/components/analytics'
import { LabelRelationship, ReleaseTrackingDataFragmentDoc, Format, PrimaryArtist } from '../../api/types'

type ReleaseInteractionData = {
    title: string
    genre: string
    style: string
    label: string
    artist: string
    format: string
}

export const Page = createPage({
    pageCategory: 'release-view-new',
    objectType: 'release',
    interaction(client: ApolloClient<unknown>, discogsId?: number): ReleaseInteractionData | null {
        if (!discogsId) {
            return null
        }

        const data = client.readFragment({
            id: client.cache.identify({ __typename: 'Release', discogsId }),
            fragment: ReleaseTrackingDataFragmentDoc,
        })

        if (!data) {
            return null
        }

        return {
            title: data.title,
            genre: data.genres.join(', '),
            style: data.styles.join(', '),
            label: data.labels.map((label: LabelRelationship): string => label.label.name).join(', '),
            artist: data.primaryArtists
                .map((artist: PrimaryArtist): string | undefined => artist.artist?.name)
                .filter((name: string | undefined): name is string => Boolean(name))
                .join(', '),
            format: data.formats.map((format: Format): string => format.name).join(', '),
        }
    },
})
