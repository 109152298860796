/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { ApolloCache, FetchResult, Reference } from '@apollo/client'
import { t } from '@lingui/macro'

import { useLogin } from '../../lib/login'
import { useErrorHandler } from '../../lib/components/errors'
import {
    useEditCollectionItemNoteMutation as useEditCollectionItemNoteMutation_,
    EditCollectionItemNoteMutation,
    useEditWantlistItemNotesMutation as useEditWantlistItemNotesMutation_,
    EditWantlistItemNotesMutation,
    useRemoveCollectionItemNoteMutation as useRemoveCollectionItemNoteMutation_,
    RemoveCollectionItemNoteMutation,
    CollectionNoteFragment,
    CollectionNoteFragmentDoc,
    useMoveCollectionItemMutation as _useMoveCollectionItemMutation_,
    MoveCollectionItemMutation,
    CollectionItemFolderFragmentDoc,
    WantlistItemFragment,
} from '../../api/types'
import { Modifier } from '@apollo/client/cache'

type Mutation<Fn extends Function = () => Promise<void>> = {
    loading: boolean
    perform: Fn
}

type NoteMarkup = CollectionNoteFragment['text']

type EditCollectionItemOptions = {
    itemId: number
}

export function useEditCollectionItemNoteMutation(
    opts: EditCollectionItemOptions,
): Mutation<(typeId: number, noteId: number | undefined, value: string) => Promise<void>> {
    const { itemId } = opts
    const [edit, info] = useEditCollectionItemNoteMutation_()
    const { user } = useLogin()
    const onError = useErrorHandler(t`Could not edit the collection note`)

    async function perform(typeId: number, noteId: number | undefined, value: string): Promise<void> {
        if (!user) {
            return
        }

        await edit({
            variables: {
                input: {
                    discogsItemId: itemId,
                    discogsNoteTypeId: typeId,
                    noteText: value,
                },
            },
            update(
                cache: ApolloCache<EditCollectionItemNoteMutation>,
                result: FetchResult<EditCollectionItemNoteMutation>,
            ) {
                if (noteId) {
                    cache.modify({
                        id: cache.identify({ __typename: 'CollectionNote', discogsId: noteId }),
                        fields: {
                            text: ((existing: NoteMarkup): NoteMarkup => {
                                if (!result.data) {
                                    return existing
                                }
                                return (
                                    result.data?.editCollectionItemNote?.collectionNote?.text ?? {
                                        html: '',
                                        markup: '',
                                    }
                                )
                                // Apollo Client (<= 3.x) has strict Modifier type requirements that
                                // can be difficult to satisfy when working with complex nested types.
                                // This type assertion works around those type mismatches.
                                // TODO: Consider refactoring to use a helper function for better type safety.
                                // https://discogsinc.atlassian.net/browse/RALM-2748
                            }) as unknown as Modifier<null>,
                        },
                    })
                    return
                }

                cache.modify({
                    id: cache.identify({ __typename: 'CollectionItem', discogsId: itemId }),
                    fields: {
                        notes: ((existing: Reference[]): Reference[] => {
                            const note = cache.writeFragment({
                                fragment: CollectionNoteFragmentDoc,
                                data: {
                                    __typename: 'CollectionNote',
                                    discogsId: Math.round(Math.random() * 1000),
                                    text: result.data?.editCollectionItemNote.collectionNote?.text,
                                    noteType: {
                                        __typename: 'CollectionNoteType',
                                        discogsId: typeId,
                                    },
                                },
                            })

                            if (!note) {
                                return existing
                            }

                            return [...existing, note]
                            // Apollo Client (<= 3.x) has strict Modifier type requirements that
                            // can be difficult to satisfy when working with complex nested types.
                            // This type assertion works around those type mismatches.
                            // TODO: Consider refactoring to use a helper function for better type safety.
                            // https://discogsinc.atlassian.net/browse/RALM-2748
                        }) as unknown as Modifier<null>,
                    },
                })
            },
        }).catch(onError)
    }

    return {
        ...info,
        perform,
    }
}

export function useRemoveCollectionItemNoteMutation(
    opts: EditCollectionItemOptions,
): Mutation<(noteId: number) => Promise<void>> {
    const { itemId } = opts
    const [del, info] = useRemoveCollectionItemNoteMutation_()
    const { user } = useLogin()
    const onError = useErrorHandler(t`Could not remove collection note`)

    async function perform(noteId: number): Promise<void> {
        if (!user) {
            return
        }

        await del({
            variables: {
                input: {
                    discogsId: noteId,
                },
            },
            optimisticResponse: {
                removeCollectionItemNote: {
                    // @ts-expect-error
                    __typename: 'RemoveCollectionNotePayload',
                    success: true,
                },
            },
            update(
                cache: ApolloCache<RemoveCollectionItemNoteMutation>,
                result: FetchResult<RemoveCollectionItemNoteMutation>,
            ) {
                cache.modify({
                    id: cache.identify({ __typename: 'CollectionItem', discogsId: itemId }),
                    fields: {
                        notes: ((existing: Reference[]): Reference[] => {
                            if (!result.data) {
                                return existing
                            }

                            const id = cache.identify({ __typename: 'CollectionNote', discogsId: noteId })
                            return existing.filter((ref: Reference): boolean => ref.__ref !== id)
                            // Apollo Client (<= 3.x) has strict Modifier type requirements that
                            // can be difficult to satisfy when working with complex nested types.
                            // This type assertion works around those type mismatches.
                            // TODO: Consider refactoring to use a helper function for better type safety.
                            // https://discogsinc.atlassian.net/browse/RALM-2748
                        }) as unknown as Modifier<null>,
                    },
                })
            },
        }).catch(onError)
    }

    return {
        ...info,
        perform,
    }
}

type EditWanlistNotesOptions = {
    discogsId: number
    notes: string
}

export function useEditWantlistItemNotesMutation(
    opts: EditWanlistNotesOptions,
): Mutation<(notes: string) => Promise<void>> {
    const { discogsId } = opts
    const [edit, info] = useEditWantlistItemNotesMutation_()
    const { user } = useLogin()
    const onError = useErrorHandler(t`Could not edit wantlist note`)

    async function perform(notes: string): Promise<void> {
        if (!user) {
            return
        }

        await edit({
            variables: {
                input: {
                    releaseDiscogsId: discogsId,
                    notes,
                },
            },
            update(
                cache: ApolloCache<EditWantlistItemNotesMutation>,
                result: FetchResult<EditWantlistItemNotesMutation>,
            ): void {
                cache.modify({
                    id: cache.identify({
                        __typename: 'WantlistItem',
                        discogsId: result.data?.editWantlistItemNotes?.wantlistItem?.discogsId,
                    }),
                    fields: {
                        notes(): WantlistItemFragment['notes'] {
                            return result.data?.editWantlistItemNotes?.wantlistItem?.notes
                        },
                    },
                })
            },
        }).catch(onError)
    }

    return {
        ...info,
        perform,
    }
}

type MoveCollectionItemOptions = {
    itemId: number
}

export function useMoveCollectionItemMutation(
    opts: MoveCollectionItemOptions,
): Mutation<(folderId: number, name: string) => Promise<void>> {
    const { itemId } = opts
    const [move, info] = _useMoveCollectionItemMutation_()
    const { user } = useLogin()
    const onError = useErrorHandler(t`Could not move collection item`)

    async function perform(folderId: number, folderName: string): Promise<void> {
        if (!user) {
            return
        }

        await move({
            variables: {
                input: {
                    discogsId: itemId,
                    discogsFolderId: folderId,
                },
            },
            optimisticResponse: {
                moveCollectionItem: {
                    __typename: 'MoveCollectionItemPayload',
                    collectionItem: {
                        __typename: 'CollectionItem',
                        discogsId: itemId,
                        folder: {
                            // @ts-expect-error: typenames are not generated
                            __typename: 'CollectionFolder',
                            discogsId: folderId,
                            name: folderName,
                        },
                    },
                },
            },
            update(
                cache: ApolloCache<MoveCollectionItemMutation>,
                result: FetchResult<MoveCollectionItemMutation>,
            ): void {
                cache.modify({
                    id: cache.identify({ __typename: 'CollectionItem', discogsId: itemId }),
                    fields: {
                        folder(): Reference | undefined {
                            if (!result.data) {
                                return undefined
                            }
                            return cache.writeFragment({
                                fragment: CollectionItemFolderFragmentDoc,
                                data: result.data.moveCollectionItem.collectionItem.folder,
                            })
                        },
                    },
                })
            },
        }).catch(onError)
    }

    return {
        ...info,
        perform,
    }
}
