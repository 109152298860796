import * as React from 'react'
import classnames from 'classnames'
import { Trans } from '@lingui/macro'

import { ReleaseVersionsFragment, ReleaseVersionFragment, ReleaseDataQuality, ReleaseLabelRole } from '../../api/types'

import { intersperse } from '../../lib/intersperse'
import { extractNodes } from '../../lib/nodes'
import { year } from '../../lib/date'

import { Section } from '../../lib/components/section'
import { Loading } from '../../lib/components/loading'
import { Link } from '../../lib/components/link'
import { Skittle } from '../../lib/components/skittle'
import { ReleaseLabelLink } from '../release-label-link'

import css from './styles.css'

type Sort = {
    type?: string
    sort_order?: string
}

export type Props = {
    data: ReleaseVersionsFragment | null | undefined
    sort?: Sort
}

export type RenderProps = {
    data: ReleaseVersionsFragment
    sort?: Sort
}

const id = 'release-other-versions'

export function ReleaseVersionTable(props: Props): React.ReactElement | null {
    const { data } = props
    if (!data) {
        return (
            <Section title={<Trans>Other Versions</Trans>} id={id}>
                <Loading height={100} />
            </Section>
        )
    }

    return <ReleaseVersionTableRender {...props} data={data} />
}

export function ReleaseVersionTableRender(props: RenderProps): React.ReactElement | null {
    const { data, sort } = props
    const { discogsId, masterRelease } = data

    if (!masterRelease?.versions || masterRelease.versions.totalCount === 0) {
        return null
    }

    const {
        discogsId: masterId,
        versions: { totalCount: total, edges },
    } = masterRelease

    const versions = extractNodes(edges)
        .filter((version: ReleaseVersionFragment): boolean => version.discogsId !== discogsId)
        .slice(0, 5)

    const truncated = edges.length < total

    const header = (
        <span className={css.headingContainer}>
            <h2 className={css.head}>
                {truncated ? (
                    <Trans>
                        Other Versions ({versions.length} of {total})
                    </Trans>
                ) : (
                    <Trans>Other Versions ({versions.length})</Trans>
                )}
            </h2>
            {masterId && (
                <Link href={masterRelease.siteUrl} className={css.all}>
                    <Trans>View All</Trans>
                </Link>
            )}
        </span>
    )

    return (
        <Section id={id} header={header}>
            <div>
                <table className={css.versions}>
                    <tbody>
                        <tr className={css.header}>
                            <td className={css.quality} />
                            <th className={css.title}>
                                <Header field='title' sort={sort}>
                                    <Trans>Title</Trans>
                                </Header>
                                {' ('}
                                <Header field='format' sort={sort}>
                                    <Trans>Format</Trans>
                                </Header>
                                )
                            </th>
                            <th className={css.label}>
                                <Header field='label' sort={sort}>
                                    <Trans>Label</Trans>
                                </Header>
                            </th>
                            <th className={css.cat}>
                                <Header field='catno' sort={sort}>
                                    <Trans>Cat#</Trans>
                                </Header>
                            </th>
                            <th className={css.country}>
                                <Header field='country' sort={sort}>
                                    <Trans>Country</Trans>
                                </Header>
                            </th>
                            <th className={css.year}>
                                <Header field='year' sort={sort}>
                                    <Trans>Year</Trans>
                                </Header>
                            </th>
                            <td />
                            <td />
                        </tr>
                        {versions.map(
                            (version: ReleaseVersionFragment | undefined): React.ReactElement | undefined =>
                                version && <Version version={version} key={version.discogsId} />,
                        )}
                    </tbody>
                </table>
            </div>
        </Section>
    )
}

type HeaderProps = {
    field: string
    sort?: Sort
    children: React.ReactElement | string
}

function Header(props: HeaderProps): React.ReactElement | null {
    const { children } = props

    return <>{children}</>
}

type VersionProps = {
    version: ReleaseVersionFragment
}

function Version(props: VersionProps): React.ReactElement | null {
    const { version } = props
    const { title, formats, labels, country, released, dataQuality, siteUrl, collectionItems, inWantlist, myListings } =
        version

    return (
        <tr>
            <Quality dataQuality={dataQuality} />
            <td>
                <Link href={siteUrl}>{title}</Link> <Formats formats={formats} />
            </td>
            <td>
                <Labels labels={labels} />
            </td>
            <td>
                <CatalogNumbers labels={labels} />
            </td>
            <td>{country}</td>
            <td>
                {released ? (
                    year(released)
                ) : (
                    <span className={css.unknown}>
                        <Trans>Unknown</Trans>
                    </span>
                )}
            </td>
            <td className={css.skittles}>
                <Skittle type='collection' count={collectionItems?.totalCount ?? 0} />
                <Skittle type='wantlist' count={inWantlist?.discogsId ? 1 : 0} />
                <Skittle type='inventory' count={myListings?.totalCount ?? 0} />
            </td>
        </tr>
    )
}

type Format = NonNullable<ReleaseVersionFragment['formats']>[0]
type FormatProps = {
    formats?: Format[]
}

function Formats(props: FormatProps): React.ReactElement | null {
    const { formats } = props

    if (!formats || formats.length === 0) {
        return null
    }

    return (
        <>
            (
            {intersperse(
                ', ',
                formats.map(function (format: Format): React.ReactElement {
                    const { name, quantity, description, text } = format
                    return (
                        <React.Fragment key={name}>
                            {quantity && quantity !== '1' && <>{quantity}&times;</>}
                            {name !== 'Vinyl' && name}
                            {name !== 'Vinyl' && description && ', '}
                            {description?.join(', ')}
                            {text && (
                                <>
                                    , <span className={css.text}>{text}</span>
                                </>
                            )}
                        </React.Fragment>
                    )
                }),
            )}
            )
        </>
    )
}

type VersionLabel = NonNullable<NonNullable<ReleaseVersionFragment['labels']>[0]>
type LabelsProps = {
    labels?: VersionLabel[]
}

function Labels(props: LabelsProps): React.ReactElement | null {
    const { labels } = props

    if (!labels) {
        return null
    }

    const filtered = labels.filter(
        (label: VersionLabel): boolean => label.labelRole === ReleaseLabelRole.Label && Boolean(label.catalogNumber),
    )

    return <ReleaseLabelLink labels={filtered} noCatNos />
}

type CatalogNumbersProps = {
    labels?: VersionLabel[]
}

function CatalogNumbers(props: CatalogNumbersProps): React.ReactElement | null {
    const { labels } = props

    if (!labels) {
        return null
    }

    const numbers = labels
        .filter((label: VersionLabel): boolean => label.labelRole === ReleaseLabelRole.Label)
        .map(function (label: VersionLabel): string | null {
            const { catalogNumber } = label

            if (!catalogNumber) {
                return null
            }

            return catalogNumber
        })
        .filter((x: string | null, i: number, a: (string | null)[]): boolean => x !== null && a.indexOf(x) === i)

    return <>{intersperse(', ', numbers)}</>
}

type QualityProps = {
    dataQuality: ReleaseDataQuality
}

const quality = {
    [ReleaseDataQuality.NewSubmission]: css.newsubmission,
    [ReleaseDataQuality.NeedsChanges]: css.needschanges,
    [ReleaseDataQuality.ReviewChanges]: css.reviewchanges,
    [ReleaseDataQuality.Correct]: null,
}

const labels = {
    [ReleaseDataQuality.NewSubmission]: <Trans>New Submission</Trans>,
    [ReleaseDataQuality.NeedsChanges]: <Trans>Needs Changes</Trans>,
    [ReleaseDataQuality.ReviewChanges]: <Trans>Recently Edited</Trans>,
    [ReleaseDataQuality.Correct]: null,
}

function Quality(props: QualityProps): React.ReactElement | null {
    const { dataQuality } = props

    const label = labels[dataQuality]
    if (!label) {
        return <td className={classnames(css.quality, css.none)} />
    }

    return (
        <td className={classnames(css.quality, quality[dataQuality])}>
            <div className={css.tooltip}>
                <div className={css.label}>{label}</div>
            </div>
        </td>
    )
}
