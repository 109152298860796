import * as React from 'react'

import { Ratings } from '../../lib/components/ratings'
import { useLogin, login } from '../../lib/login'
import { delay } from '../../lib/delay'

import { UserReleaseRatingFragment } from '../../api/types'
import { useRateReleaseMutation, useRemoveReleaseRatingMutation } from './mutation'
import { useInteraction } from '../../lib/components/analytics'
import css from './styles.css'
import classNames from 'classnames'

type Props = UserReleaseRatingFragment & {
    addPadding?: boolean
}

export function ReleaseRatings(props: Props): React.ReactElement {
    const { discogsId, rating, addPadding } = props
    const { user } = useLogin()
    const [rate] = useRateReleaseMutation({ discogsId, rating })
    const [unrate] = useRemoveReleaseRatingMutation({ discogsId, rating })
    const track = useInteraction()

    async function handleRate(value: number | null): Promise<void> {
        track('Add Rating')
        await delay(10)
        if (!user) {
            login()
            return
        }

        if (value === null) {
            await unrate()
        } else {
            await rate(value)
        }
    }

    const classnames = classNames(css.ratings, addPadding && css.addPadding)

    return (
        <div className={classnames}>
            <Ratings value={rating?.value ?? 0} onChange={handleRate} />
        </div>
    )
}
