import * as React from 'react'
import { Trans } from '@lingui/macro'

import { Barcode, BarcodeType, ReleaseBarcodesFragment } from '../../api/types'
import { Section } from '../../lib/components/section'

import css from './styles.css'
import { Typography } from '@mui/material'

const names = {
    [BarcodeType.Barcode]: <Trans>Barcode</Trans>,
    [BarcodeType.LabelCode]: <Trans>Label Code</Trans>,
    [BarcodeType.MatrixRunout]: <Trans>Matrix / Runout</Trans>,
    [BarcodeType.MasteringSidCode]: <Trans>Mastering SID Code</Trans>,
    [BarcodeType.MouldSidCode]: <Trans>Mould SID Code</Trans>,
    [BarcodeType.PressingPlantId]: <Trans>Pressing Plant ID</Trans>,
    [BarcodeType.DistributionCode]: <Trans>Distribution Code</Trans>,
    [BarcodeType.PriceCode]: <Trans>Price Code</Trans>,
    [BarcodeType.SparsCode]: <Trans>SPARS Code</Trans>,
    [BarcodeType.DepositoLegal]: <Trans>Depósito Legal</Trans>,
    [BarcodeType.Asin]: <Trans>ASIN</Trans>,
    [BarcodeType.Isrc]: <Trans>ISRC</Trans>,
    [BarcodeType.RightsSociety]: <Trans>Rights Society</Trans>,
    [BarcodeType.Other]: <Trans>Other</Trans>,
}

export function ReleaseBarcodes(props: ReleaseBarcodesFragment): React.ReactElement | null {
    const { barcodes = [] } = props

    if (barcodes.length === 0) {
        return null
    }

    return (
        <Section
            id='release-barcodes'
            title={
                <Typography variant='headLineLarge'>
                    <Trans>Barcode and Other Identifiers</Trans>
                </Typography>
            }
        >
            <ul className={css.simple}>
                {barcodes.map(function (barcode: Barcode): React.ReactElement {
                    const { value, type: typ, description } = barcode

                    return (
                        <li key={value}>
                            <Typography variant='labelSmall'>
                                {typ && names[typ]}
                                {description && ` (${description})`}: {value}
                            </Typography>
                        </li>
                    )
                })}
            </ul>
        </Section>
    )
}
