import * as React from 'react'
import { ReleaseTracklistFragment } from '../../api/types'
import { addState } from '../../lib/add-state'
import { usePersist } from '../../lib/use-persist'
import { ReleaseTracklistUI } from './ui'

export type Props = ReleaseTracklistFragment

export type State = {
    perPage: number | 'all'
    page: number
}

export type Action = { type: 'next' } | { type: 'prev' } | { type: 'per-page'; perPage: number | 'all' }

export function reducer(props: Props, state: State, action: Action): State {
    switch (action.type) {
        case 'next':
            if (state.perPage !== 'all') {
                return {
                    ...state,
                    page: Math.min(Math.ceil((props.tracks?.length ?? 0) / state.perPage), state.page + 1),
                }
            }
            return state
        case 'prev':
            if (state.perPage !== 'all') {
                return { ...state, page: Math.max(0, state.page - 1) }
            }
            return state
        case 'per-page':
            return {
                ...state,
                perPage: action.perPage,
                page: 0,
            }
        default:
            return state
    }
}

function useInitial(): State {
    const [value] = usePersist('tracklist_items_per_page')

    const perPage = typeof value === 'number' || value === 'all' ? value : 25

    return {
        perPage,
        page: 0,
    }
}

function useEffect(props: Props, state: State): void {
    const [value, setPerPage] = usePersist('tracklist_items_per_page')
    React.useEffect(
        function () {
            setPerPage(state.perPage)
        },
        [value, state.perPage],
    )
}

export const ReleaseTracklist = addState(ReleaseTracklistUI, reducer, useInitial, useEffect)
