import { ApolloCache, FetchResult } from '@apollo/client'
import {
    useRateReleaseMutation as useRateReleaseMutation_,
    Rating,
    RateReleaseMutation,
    useRemoveReleaseRatingMutation as useRemoveReleaseRatingMutation_,
    RemoveReleaseRatingMutation,
} from '../../api/types'
import { Modifier } from '@apollo/client/cache'

type Info = {
    loading: boolean
    error?: Error
}

type RateMutation = [(value: number) => Promise<void>, Info]
type UnrateMutation = [() => Promise<void>, Info]

type Options = {
    discogsId: number
    rating: Partial<Rating> | undefined
}

type RatingsConnection = {
    totalCount: number
    averageRating: number
}

function round(value: number): number {
    return Math.round(value * 100) / 100
}

export function useRateReleaseMutation(opts: Options): RateMutation {
    const { discogsId, rating: hasRating } = opts
    const [perform, info] = useRateReleaseMutation_()

    async function rate(rating: number): Promise<void> {
        await perform({
            variables: {
                input: {
                    discogsId,
                    rating,
                },
            },
            optimisticResponse: {
                rateRelease: {
                    __typename: 'RateReleasePayload',
                    rating: {
                        // @ts-expect-error
                        __typename: 'Rating',
                        id: Math.random().toString(),
                        value: rating,
                    },
                },
            },
            update(cache: ApolloCache<RateReleaseMutation>, result: FetchResult<RateReleaseMutation>): void {
                cache.modify({
                    id: cache.identify({ __typename: 'Release', discogsId }),
                    fields: {
                        rating(): Partial<Rating> {
                            return {
                                // @ts-expect-error
                                __typename: 'Rating',
                                id: Math.random().toString(),
                                value: rating,
                            }
                        },
                        ratings: ((existing: RatingsConnection | undefined): RatingsConnection | undefined => {
                            if (!existing) {
                                return {
                                    totalCount: 1,
                                    averageRating: rating,
                                }
                            }

                            const averageRating = round(
                                (existing.totalCount * existing.averageRating + rating) / (existing.totalCount + 1),
                            )

                            return {
                                ...existing,
                                totalCount: existing.totalCount + (hasRating ? 0 : 1),
                                averageRating,
                            }
                            // Apollo Client (<= 3.x) has strict Modifier type requirements that
                            // can be difficult to satisfy when working with complex nested types.
                            // This type assertion works around those type mismatches.
                            // TODO: Consider refactoring to use a helper function for better type safety.
                            // https://discogsinc.atlassian.net/browse/RALM-2748
                        }) as unknown as Modifier<null>,
                    },
                })
            },
        })
    }

    return [rate, info]
}

type RemoveOptions = {
    discogsId: number
    rating: Partial<Rating> | undefined
}

export function useRemoveReleaseRatingMutation(opts: RemoveOptions): UnrateMutation {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const { discogsId, rating } = opts
    const [perform, info] = useRemoveReleaseRatingMutation_()

    async function unrate(): Promise<void> {
        if (!rating) {
            return
        }

        await perform({
            variables: {
                input: {
                    discogsId,
                },
            },
            optimisticResponse: {
                removeReleaseRating: {
                    // @ts-expect-error
                    __typename: 'RemoveReleaseRatingPayload',
                    success: true,
                },
            },
            update(
                cache: ApolloCache<RemoveReleaseRatingMutation>,
                result: FetchResult<RemoveReleaseRatingMutation>,
            ): void {
                cache.modify({
                    id: cache.identify({ __typename: 'Release', discogsId }),
                    fields: {
                        rating(existing: Partial<Rating>, options: any): any {
                            return options.DELETE
                        },
                        ratings: ((existing: RatingsConnection | undefined): RatingsConnection | undefined => {
                            if (!existing) {
                                return existing
                            }

                            const averageRating = round(
                                (existing.totalCount * existing.averageRating - (rating.value ?? 0)) /
                                    (existing.totalCount - 1),
                            )

                            return {
                                ...existing,
                                totalCount: existing.totalCount - 1,
                                averageRating,
                            }
                            // Apollo Client (<= 3.x) has strict Modifier type requirements that
                            // can be difficult to satisfy when working with complex nested types.
                            // This type assertion works around those type mismatches.
                            // TODO: Consider refactoring to use a helper function for better type safety.
                            // https://discogsinc.atlassian.net/browse/RALM-2748
                        }) as unknown as Modifier<null>,
                    },
                })
            },
        })
    }

    return [unrate, info]
}
