import * as React from 'react'
import { I18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'

import { ReleaseLabelRole } from '../../api/types'

import { useLocalisation } from '../../lib/i18n'
import { Section } from '../../lib/components/section'
import { LabelRelationship } from '../release-label-link'

import css from './styles.css'
import { Typography, Link } from '@mui/material'

export type Company = LabelRelationship

type Props = {
    labels?: LabelRelationship[]
}

export function ReleaseCompanies(props: Props): React.ReactElement | null {
    const { labels: companies = [] } = props

    const nonLabel = companies
        .filter((co: Company): boolean => co.labelRole !== ReleaseLabelRole.Label)
        .filter((co: Company): boolean => co.labelRole !== ReleaseLabelRole.Series)

    if (nonLabel.length === 0) {
        return null
    }

    return (
        <Section
            id='release-companies'
            title={
                <Typography variant='headLineLarge'>
                    <Trans>Companies, etc.</Trans>
                </Typography>
            }
        >
            <ul className={css.list}>
                {nonLabel.map(function (company: Company): React.ReactElement | null {
                    const { label, labelRole, catalogNumber, displayName } = company
                    const name = displayName ?? label.name
                    const discogsId = 'discogsId' in label && label.discogsId
                    const href = 'siteUrl' in label && label.siteUrl
                    const link = href ? <Link href={href}>{name}</Link> : <span>{name}</span>

                    return (
                        <li key={`${discogsId || name}-${labelRole ?? ''}`}>
                            <Typography variant='labelSmall'>
                                {labelRole && (
                                    <>
                                        <span className={css.entity}>
                                            <LabelRole labelRole={labelRole} />
                                        </span>
                                        {' – '}
                                    </>
                                )}
                                {link}
                                {catalogNumber && ` – ${catalogNumber}`}
                            </Typography>
                        </li>
                    )
                })}
            </ul>
        </Section>
    )
}

const labelRoleNames = {
    [ReleaseLabelRole.Label]: (i18n: I18n) => t(i18n)`Label`,
    [ReleaseLabelRole.Series]: (i18n: I18n) => t(i18n)`Series`,
    [ReleaseLabelRole.RecordCompany]: (i18n: I18n) => t(i18n)`Record Company`,
    [ReleaseLabelRole.LicensedTo]: (i18n: I18n) => t(i18n)`Licensed To`,
    [ReleaseLabelRole.LicensedFrom]: (i18n: I18n) => t(i18n)`Licensed From`,
    [ReleaseLabelRole.LicensedThrough]: (i18n: I18n) => t(i18n)`Licensed Through`,
    [ReleaseLabelRole.MarketedBy]: (i18n: I18n) => t(i18n)`Marketed By`,
    [ReleaseLabelRole.DistributedBy]: (i18n: I18n) => t(i18n)`Distributed By`,
    [ReleaseLabelRole.ManufacturedBy]: (i18n: I18n) => t(i18n)`Manufactured By`,
    [ReleaseLabelRole.ExportedBy]: (i18n: I18n) => t(i18n)`Exported By`,
    [ReleaseLabelRole.PhonographicCopyright]: (i18n: I18n) => t(i18n)`Phonographic Copyright ℗`,
    [ReleaseLabelRole.Copyright]: (i18n: I18n) => t(i18n)`Copyright ©`,
    [ReleaseLabelRole.MadeBy]: (i18n: I18n) => t(i18n)`Made By`,
    [ReleaseLabelRole.PressedBy]: (i18n: I18n) => t(i18n)`Pressed By`,
    [ReleaseLabelRole.DuplicatedBy]: (i18n: I18n) => t(i18n)`Duplicated By`,
    [ReleaseLabelRole.PrintedBy]: (i18n: I18n) => t(i18n)`Printed By`,
    [ReleaseLabelRole.PublishedBy]: (i18n: I18n) => t(i18n)`Published By`,
    [ReleaseLabelRole.RecordedAt]: (i18n: I18n) => t(i18n)`Recorded At`,
    [ReleaseLabelRole.EngineeredAt]: (i18n: I18n) => t(i18n)`Engineered At`,
    [ReleaseLabelRole.OverdubbedAt]: (i18n: I18n) => t(i18n)`Overdubbed At`,
    [ReleaseLabelRole.ProducedAt]: (i18n: I18n) => t(i18n)`Produced At`,
    [ReleaseLabelRole.MixedAt]: (i18n: I18n) => t(i18n)`Mixed At`,
    [ReleaseLabelRole.RemixedAt]: (i18n: I18n) => t(i18n)`Remixed At`,
    [ReleaseLabelRole.MasteredAt]: (i18n: I18n) => t(i18n)`Mastered At`,
    [ReleaseLabelRole.LacquerCutAt]: (i18n: I18n) => t(i18n)`Lacquer Cut At`,
    [ReleaseLabelRole.GlassMasteredAt]: (i18n: I18n) => t(i18n)`Glass Mastered At`,
    [ReleaseLabelRole.DesignedAt]: (i18n: I18n) => t(i18n)`Designed At`,
    [ReleaseLabelRole.FilmedAt]: (i18n: I18n) => t(i18n)`Filmed At`,
    [ReleaseLabelRole.RemasteredAt]: (i18n: I18n) => t(i18n)`Remastered At`,
    [ReleaseLabelRole.EditedAt]: (i18n: I18n) => t(i18n)`Edited At`,
    [ReleaseLabelRole.ProducedFor]: (i18n: I18n) => t(i18n)`Produced For`,
    [ReleaseLabelRole.ManufacturedFor]: (i18n: I18n) => t(i18n)`Manufactured For`,
    [ReleaseLabelRole.RecordedBy]: (i18n: I18n) => t(i18n)`Recorded By`,
    [ReleaseLabelRole.ExclusiveRetailer]: (i18n: I18n) => t(i18n)`Exclusive Retailer`,
}

type LabelRoleProps = {
    labelRole: ReleaseLabelRole
}

function LabelRole(props: LabelRoleProps): React.ReactElement {
    const { labelRole } = props
    const { i18n } = useLocalisation()
    const name = labelRole in labelRoleNames ? labelRoleNames[labelRole](i18n) : labelRole
    return <>{name}</>
}
