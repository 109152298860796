import * as React from 'react'
import { Trans } from '@lingui/macro'

import { Format } from '../../api/types'
import { Format as FormatName, asFormat } from '../../lib/format'
import { Link, Typography } from '@mui/material'

type FormatLinkProps = {
    formats: Format[]
}

const formatNames = {
    [FormatName.CD]: <Trans>CD</Trans>,
    [FormatName.CDr]: <Trans>CDr</Trans>,
    [FormatName.DVD]: <Trans>DVD</Trans>,
    [FormatName.Cassette]: <Trans>Cassette</Trans>,
    [FormatName.File]: <Trans>File</Trans>,
    [FormatName.Vinyl]: <Trans>Vinyl</Trans>,
    [FormatName.BoxSet]: <Trans>Box Set</Trans>,
    [FormatName.BluRay]: <Trans>Blu-ray</Trans>,
    [FormatName.VHS]: <Trans>VHS</Trans>,
    [FormatName.ReelToReel]: <Trans>Reel-To-Reel</Trans>,
    [FormatName.UMatic]: <Trans>U-Matic</Trans>,
    [FormatName.BetacamSP]: <Trans>Betacam-SP</Trans>,
    [FormatName.AllMedia]: <Trans>All Media</Trans>,
    [FormatName.Unknown]: <Trans>Unknown</Trans>,
}

export function ReleaseFormatLink(props: FormatLinkProps): React.ReactElement {
    const { formats } = props
    const path = '/search/?format_exact='

    return (
        <>
            {formats.map(function (f: Format) {
                const fmt = asFormat(f.name)
                const fname = fmt === FormatName.Unknown ? f.name : formatNames[fmt]

                return (
                    <div key={f.name}>
                        {f.quantity && f.quantity !== '1' && (
                            <Typography variant='labelSmall'>{`${f.quantity} x `}</Typography>
                        )}
                        <Link variant='labelSmall' href={`${path}${f.name}`}>
                            {fname}
                        </Link>
                        {f.description && f.description.length > 0 && (
                            <Typography variant='labelSmall'>, {f.description.join(', ')}</Typography>
                        )}
                        {f.text && (
                            <Typography variant='labelSmall'>
                                , <i>{f.text}</i>
                            </Typography>
                        )}
                    </div>
                )
            })}
        </>
    )
}
