import * as React from 'react'
import { Trans } from '@lingui/macro'

import { ReleaseStatsPricesFragment } from '../../api/types'
import { Section } from '../../lib/components/section'
import { Currency } from '../../lib/components/currency'
import { FullDate } from '../../lib/components/date'
import { Link } from '../../lib/components/link'

import css from './styles.css'

export type Props = {
    discogsId: number
    error?: Error
    loading: boolean
    data?: {
        release?: ReleaseStatsPricesFragment
    }
}

const placeholder = '--'

export function ReleaseStatsUI(props: Props): React.ReactElement {
    // TODO: This needs to be fixed in the API

    const { discogsId, data, loading } = props

    const {
        blockedFromSale = false,
        ratings: { averageRating = placeholder, totalCount = placeholder } = {},
        inCollectionCount = placeholder,
        inWantlistCount = placeholder,
        statistics: { lastSaleDate = null, min = null, max = null, median = null } = {},
    } = data?.release ?? {}

    return (
        <Section id='release-stats' title={<Trans>Statistics</Trans>} isOpenByDefault>
            <div className={css.items}>
                <ul>
                    <li>
                        <span className={css.name}>
                            <Trans>Have</Trans>:
                        </span>
                        <Link href={`/release/stats/${discogsId}`}>{inCollectionCount}</Link>
                    </li>
                    <li>
                        <span className={css.name}>
                            <Trans>Want</Trans>:
                        </span>
                        <Link href={`/release/stats/${discogsId}`}>{inWantlistCount}</Link>
                    </li>
                    <li>
                        <span className={css.name}>
                            <Trans>Avg Rating</Trans>:
                        </span>
                        <span>
                            {loading && placeholder}
                            {!loading && totalCount !== 0 && averageRating}
                            {!loading && totalCount === 0 && placeholder} / 5
                        </span>
                    </li>
                    <li>
                        <span className={css.name}>
                            <Trans>Ratings</Trans>:
                        </span>
                        <Link href={`/release/stats/${discogsId}`}>{totalCount}</Link>
                    </li>
                </ul>
                <ul>
                    <li>
                        <span className={css.name}>
                            <Trans>Last Sold</Trans>:
                        </span>
                        {loading && <span>{placeholder}</span>}
                        {(blockedFromSale || !lastSaleDate) && !loading && (
                            <span>
                                <Trans>Never</Trans>
                            </span>
                        )}
                        {!blockedFromSale && lastSaleDate && !loading && (
                            <Link href={`/sell/history/${discogsId}`}>
                                <FullDate value={new Date(lastSaleDate)} />
                            </Link>
                        )}
                    </li>
                    <li>
                        <span className={css.name}>
                            <Trans>Low</Trans>:
                        </span>
                        <span>{!blockedFromSale && min ? <Currency value={min.converted} /> : placeholder}</span>
                    </li>
                    <li>
                        <span className={css.name}>
                            <Trans>Median</Trans>:
                        </span>
                        <span>{!blockedFromSale && median ? <Currency value={median.converted} /> : placeholder}</span>
                    </li>
                    <li>
                        <span className={css.name}>
                            <Trans>High</Trans>:
                        </span>
                        <span>{!blockedFromSale && max ? <Currency value={max.converted} /> : placeholder}</span>
                    </li>
                </ul>
            </div>
        </Section>
    )
}
