type WithRole = {
    creditRole?: string
}

/**
 * Returns a new object whose keys consist of the `role` property
 * from ExtraArtist and Array<ExtraArtist> values representing
 * each artist credit. We do this in order to generate a single
 * role with multiple artist credits.
 *
 * e.g.: Composed By – Brian Eno, Harold Budd
 */
export function groupByRole<T extends WithRole>(items: T[]): { [key: string]: T[] } {
    const roles: { [key: string]: T[] } = {}

    for (const item of items) {
        const { creditRole = '' } = item
        if (!(creditRole in roles)) {
            roles[creditRole] = []
        }
        roles[creditRole].push(item)
    }
    return roles
}
