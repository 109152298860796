import * as React from 'react'
import classnames from 'classnames'
import { Trans, t } from '@lingui/macro'

import { ArtistLink } from '../artist-link'
import { ReleaseArtistLink } from '../release-artist-link'

import { WithProps } from '../../lib/add-state'
import { groupByRole } from '../../lib/group-by-role'
import { intersperse } from '../../lib/intersperse'
import { formatDuration } from '../../lib/format-duration'
import { useLocalisation } from '../../lib/i18n'
import { usePersist } from '../../lib/use-persist'
import { LevelUp, ChevronRight, ChevronLeft, EyeSlash, Eye } from '../../lib/components/icon'
import { Section } from '../../lib/components/section'

import { TrackCredit, Track, SubTrack, TrackType } from '../../api/types'
import { Props, State, Action } from '.'

import css from './styles.css'
import { useTheme } from '@mui/material/styles'
import { Typography, Button, SvgIcon } from '@mui/material'

type UIProps = WithProps<Props, State, Action>

type TrackCreditsProps = {
    credits: TrackCredit[]
    expanded?: boolean
    tabIndex?: number
}

export function useHeight(
    expanded: boolean,
    ref: React.RefObject<HTMLDivElement>,
    mref: React.RefObject<HTMLDivElement>,
): void {
    // Manually set the height of the credits section
    // so we can animate the height.
    // This is why we render the measure component, we use that
    // to have a reference of what the height of the credits
    // section will be.
    // Also, listen to changes in the window size and check if we need
    // to update the height of the tracks (when line breaks happen for instance).
    React.useEffect(
        function (): () => void {
            let t: number | undefined = undefined
            function handler(): void {
                if (!ref.current || !mref.current) {
                    return
                }

                clearTimeout(t)

                if (!expanded) {
                    ref.current.style.height = '0px'
                    ref.current.style.overflowY = 'hidden'
                    return
                }

                const { height } = mref.current.getBoundingClientRect()
                ref.current.style.height = `${height}px`
                t = window.setTimeout(function () {
                    if (!ref.current) {
                        return
                    }
                    ref.current.style.overflowY = 'visible'
                }, 400)
            }

            handler()
            window.addEventListener('resize', handler)
            return (): void => window.removeEventListener('resize', handler)
        },
        [expanded],
    )
}

function TrackCredits(props: TrackCreditsProps): React.ReactElement {
    const { credits, expanded } = props
    const roles = groupByRole(credits)

    const ref = React.useRef<HTMLDivElement>(null)
    const mref = React.useRef<HTMLDivElement>(null)

    useHeight(Boolean(expanded), ref, mref)

    function trackCreditContent(props: { tabIndex?: number }) {
        const { tabIndex } = props
        const content = Object.entries(roles).map(function ([role, creds]: [
            string,
            TrackCredit[],
        ]): React.ReactElement {
            const links = creds
                .map(function (credit: TrackCredit): React.ReactElement | null {
                    if (!credit.artist && !credit.nameVariation) {
                        return null
                    }
                    return (
                        <ArtistLink
                            tabIndex={tabIndex}
                            artist={credit.artist}
                            displayName={credit.displayName}
                            nameVariation={credit.nameVariation}
                            key={credit.artist?.name ?? credit.nameVariation}
                        />
                    )
                })
                .filter((link: React.ReactElement | null): boolean => link !== null)

            if (links.length === 0) {
                return <div key={role} />
            }

            return (
                <div key={role}>
                    <Typography variant='labelXSmall'>
                        <span>{role}</span>
                        {' – '}
                        {intersperse(', ', links)}
                    </Typography>
                </div>
            )
        })
        return content
    }

    return (
        <>
            <div className={classnames(css.credits, expanded && css.expanded, css.trackCredits)} ref={ref}>
                {trackCreditContent({})}
            </div>
            <div className={css.measure} ref={mref} aria-hidden='true'>
                {trackCreditContent({ tabIndex: -1 })}
            </div>
        </>
    )
}

type IndexTrackProps = Track & {
    showCredits: boolean
    hasArtists?: boolean
}

function IndexTrack(props: IndexTrackProps): React.ReactElement {
    const { primaryArtists, trackCredits, title, durationInSeconds, subTracks, showCredits, hasArtists } = props
    const theme = useTheme()

    return (
        <>
            <tr className={css.heading} style={{ backgroundColor: theme.palette.background.secondary }}>
                <td className={css.trackPos}>
                    <Typography variant='labelSmall'>{props.position}</Typography>
                </td>
                <td className={css.artist}>
                    {primaryArtists && primaryArtists.length > 0 && (
                        <Typography variant='labelSmall'>
                            <ReleaseArtistLink artists={primaryArtists} />
                            {hasArtists && <span className={css.dash}>&ndash;</span>}
                        </Typography>
                    )}
                </td>
                <td className={css.trackTitle}>
                    {title}
                    {trackCredits && trackCredits.length > 0 && (
                        <TrackCredits credits={trackCredits} expanded={showCredits} />
                    )}
                </td>
                <td className={css.duration}>{durationInSeconds ? `(${formatDuration(durationInSeconds)})` : null}</td>
            </tr>
            {subTracks?.map((st: SubTrack) => (
                <SubTrackR
                    key={st.position}
                    {...st}
                    showCredits={showCredits}
                    hasArtists={Boolean(primaryArtists?.length)}
                />
            ))}
        </>
    )
}

type SubTrackProps = Track & {
    showCredits: boolean
    hasArtists?: boolean
}

function SubTrackR(props: SubTrackProps): React.ReactElement {
    const { title, durationInSeconds, position, primaryArtists, trackCredits, showCredits, hasArtists } = props
    return (
        <tr className={css.subtrack}>
            <td className={css.subtrackPos}>
                <Typography variant='labelSmall'>
                    <LevelUp className={css.subtrackIcon} />
                    {position}
                </Typography>
            </td>
            <td className={css.artist}>
                {primaryArtists?.length ? (
                    <Typography variant='labelSmall'>
                        <ReleaseArtistLink artists={primaryArtists} />
                    </Typography>
                ) : null}
                {(Boolean(primaryArtists?.length) || hasArtists) && (
                    <span className={css.dash}>
                        <Typography variant='labelSmall'>&ndash;</Typography>
                    </span>
                )}
            </td>
            <td className={css.trackTitle}>
                <Typography variant='labelSmall'>{title}</Typography>
                {trackCredits && trackCredits.length > 0 && (
                    <TrackCredits credits={trackCredits} expanded={showCredits} />
                )}
            </td>
            <td className={css.duration}>
                <Typography variant='labelSmall'>{durationInSeconds && formatDuration(durationInSeconds)}</Typography>
            </td>
        </tr>
    )
}

type HeadingTrackProps = Track

function HeadingTrack(props: HeadingTrackProps): React.ReactElement {
    const { title, durationInSeconds, position } = props
    const theme = useTheme()
    return (
        <tr className={css.heading} key={title} style={{ backgroundColor: theme.palette.background.secondary }}>
            <td className={css.trackPos}>
                <Typography variant='headLineSmall'>{position}</Typography>
            </td>
            <td className={css.artist} />
            <td className={css.trackTitle}>
                <Typography variant='headLineSmall'>{title}</Typography>
            </td>
            <td className={css.duration}>
                <Typography variant='headLineSmall'>
                    {durationInSeconds && formatDuration(durationInSeconds)}
                </Typography>
            </td>
        </tr>
    )
}

type DefaultTrackProps = Track & {
    showCredits: boolean
}

function DefaultTrack(props: DefaultTrackProps): React.ReactElement {
    const { primaryArtists, trackCredits, title, durationInSeconds, position, showCredits } = props
    return (
        <tr data-track-position={position}>
            <td className={css.trackPos}>
                <Typography variant='labelSmall'>{position}</Typography>
            </td>
            <td className={css.artist}>
                {primaryArtists && primaryArtists.length > 0 && (
                    <Typography variant='labelSmall'>
                        <ReleaseArtistLink artists={primaryArtists} />
                        <span className={css.dash}>&ndash;</span>
                    </Typography>
                )}
            </td>
            <td className={css.trackTitle}>
                <Typography variant='labelSmall'>
                    <span className={css.trackTitle}>{title}</span>
                </Typography>
                {trackCredits && trackCredits.length > 0 && (
                    <Typography variant='labelXSmall'>
                        <TrackCredits credits={trackCredits} expanded={showCredits} />
                    </Typography>
                )}
            </td>
            {durationInSeconds && (
                <td className={css.duration}>
                    <Typography variant='labelSmall'>
                        <span>{formatDuration(durationInSeconds)}</span>
                    </Typography>
                </td>
            )}
        </tr>
    )
}

export function ReleaseTracklistUI(props: UIProps): React.ReactElement | null {
    const { tracks, page, dispatch } = props
    const { i18n } = useLocalisation()
    const [disableShowHideCreditButton, toggleDisableShowHideCreditButton] = React.useState(false)

    // Force no pagination
    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    const perPage: 'all' | number = 'all'

    const [hideCredits, setHideCredits] = usePersist('hide_tracklist_credits')
    const expanded = hideCredits !== 1

    function onToggle(): void {
        // Temporarliy disables the button to prevent users from clicking too fast
        // and showing overlapping text
        toggleDisableShowHideCreditButton(true)

        setHideCredits(expanded ? 1 : 0)

        setTimeout(function () {
            toggleDisableShowHideCreditButton(false)
        }, 500)
    }

    if (!tracks || tracks.length === 0) {
        return null
    }

    const shouldDisplayToggle = tracks.reduce(function (acc: boolean, track: Track): boolean {
        return acc || Boolean(track.trackCredits && track.trackCredits.length !== 0)
    }, false)

    const header = (
        <>
            <Typography variant='headLineLarge' component='h2'>
                <Trans>Tracklist</Trans>
            </Typography>
            <span className={css.spacer} />
            {shouldDisplayToggle && (
                <Button
                    size='small'
                    startIcon={<SvgIcon>{expanded ? <EyeSlash /> : <Eye />}</SvgIcon>}
                    className={css.toggle}
                    onClick={onToggle}
                    disabled={disableShowHideCreditButton}
                >
                    <Typography variant='labelSmall'>
                        {expanded ? <Trans>Hide Credits</Trans> : <Trans>Show Credits</Trans>}
                    </Typography>
                </Button>
            )}
        </>
    )

    function next(): void {
        dispatch({ type: 'next' })
    }

    function prev(): void {
        dispatch({ type: 'prev' })
    }

    function onPerPage(evt: React.ChangeEvent<HTMLSelectElement>): void {
        const value = evt.target.value === 'all' ? 'all' : parseInt(evt.target.value, 10)
        dispatch({ type: 'per-page', perPage: value })
    }

    const min = perPage === 'all' ? 0 : page * perPage
    const max = perPage === 'all' ? tracks.length : Math.min(tracks.length, (page + 1) * perPage)
    const pages = perPage === 'all' ? 0 : Math.ceil(tracks.length / perPage)
    const slice = tracks.slice(min, max)

    const buttons =
        tracks.length > 40 && (
            <div className={css.buttons}>
                <select onChange={onPerPage} value={perPage}>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='all'>{t(i18n)`Show all ${tracks.length} tracks`}</option>
                </select>
                <span className={css.info}>
                    {perPage !== 'all' && (
                        <Trans>
                            Tracks {(min + 1).toLocaleString()} &mdash; {max.toLocaleString()} of{' '}
                            {tracks.length.toLocaleString()}
                        </Trans>
                    )}
                </span>
                <Button onClick={prev} disabled={page === 0}>
                    <Trans>
                        <ChevronLeft />
                        Prev
                    </Trans>
                </Button>
                <Button onClick={next} disabled={page >= pages - 1}>
                    <Trans>
                        Next
                        <ChevronRight />
                    </Trans>
                </Button>
            </div>
        ) &&
        false

    return (
        <Section id='release-tracklist' header={header} isOpenByDefault>
            <table className={css.tracklist}>
                <tbody>
                    {slice.map(function (track: Track, index: number) {
                        const key = `${track.position ?? ''}-${page}-${index}`
                        switch (track.trackType) {
                            case TrackType.Index:
                                return <IndexTrack {...track} showCredits={expanded} key={key} />

                            case TrackType.Heading:
                                return <HeadingTrack {...track} key={key} />

                            case TrackType.Track:
                            default:
                                return <DefaultTrack {...track} showCredits={expanded} key={key} />
                        }
                    })}
                </tbody>
            </table>
            {buttons}
        </Section>
    )
}
