import * as React from 'react'
import css from './styles.css'

type Props = {
    left: React.ReactNode
    right: React.ReactNode
}

export function Split(props: Props): React.ReactElement {
    const { left, right } = props

    return (
        <div className={css.wrapper}>
            <div className={css.left}>{left}</div>
            <div className={css.right}>{right}</div>
        </div>
    )
}
